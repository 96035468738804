/*
    // 2021.03.30
    // 画像拡大モーダル表示JS
*/

$(function() {

  //------------------------------
  // COOKIE
  //------------------------------

  // cookie設定
  function setCookie(key, val) {
    var now = new Date();
    now.setDate(now.getDate() + 1);
    document.cookie = key+'='+val+';path=/;';
  }

  // cookie取得
  function getCookie( name ) {
    var result = null;
    var cookieName = name + '=';
    var allcookies = document.cookie;
    var position = allcookies.indexOf( cookieName );
    if( position != -1 ) {
      var startIndex = position + cookieName.length;
      var endIndex = allcookies.indexOf( ';', startIndex );
      if( endIndex == -1 ) {
        endIndex = allcookies.length;
      }
      result = decodeURIComponent(allcookies.substring( startIndex, endIndex ));
    }
    return result;
  }

  // cookie確認
  var pathname = location.pathname;
  const keyName = 'visited';
  const keyValue = 'false';
  const visitedCookie = getCookie(keyName);
  const keyPathName = pathname;
  const keyPathValue = 'true';
  // const pathCookie = getCookie(keyPathName);

  //------------------------------
  // end of COOKIE
  //------------------------------


  $('.img__modal--button').on('click', function() {

    // モーダルの表示
    $("body").append('<div class="img__modal--overlay"></div>');
    var target = $(this).next('.img__modal');
    target.show();

    // 位置調整
    // var width  = $(window).width();
    // var height = $(window).height();
    //
    // var modalWidth = $(".img__modal").outerWidth();
    // var modalHeight = $(".img__modal").outerHeight();
    //
    // var left = ((width  - modalWidth)/2);
    // var top  = ((height - modalHeight)/2);
    //
    // target.css("left", left + "px");
    // target.css("top",  top  + "px");
  });

  $('.img__modal').on('click', function() {
    close();
  });

  $(document).on('click', '.img__modal--overlay', function() {
    close();
  });

  // モーダルの終了
  function close() {
    $('.img__modal').hide();
    $('.img__modal--overlay').remove();
  }

  if($(".onetime-modal").length > 0) {
      if (visitedCookie === 'true') {
        $('#js-modal').hide();
        $('.overlay').hide();
      } else {
        $("body").prepend('<div class="overlay"></div>');
        var target = $('#js-modal');
        target.show();
      }
  }

  // モーダル「はい」押した時
  $("body").on("click", ".js-modal-yes", function() {
    $('#js-modal').hide();
    $('.overlay').hide();
    setCookie(keyName, 'true');
  });
});
