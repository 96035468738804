/**
 * module_pri_load.js
 * 画像事前読み込み
 */

 priloadImages([
   // "/_front_site/htdocs/img/common/●●.png",
 ]);

 function priloadImages(images) {
 	$.each(images, function(index, value) {
 		$("<img>").attr("src", value);
 	});
 }
