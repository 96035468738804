/**
 * module_modal.js
 * モーダルの設定
 */


$(function() {

  /* modaal.jsプラグイン */
  require('lib/modaal.min.js');

  $(".close.btn").click(function() {
    // ID取得
    var id = $(this).parents(".modaal-wrapper").attr("id");

    // オーブンリング取得
    var $open_link = $("[data-modaal-scope=" + id + "]");

    // チェック
    $open_link.find("input").attr("checked", true);

    // モーダルをクローズ
    $open_link.modaal('close');
  });

  // スクロールイベント
$(".modal-window").scroll(function() {

  var winh = $(this).innerHeight(); //ページ全体の高さ
  var doch = $(this).find(".modal-content").innerHeight(); //ウィンドウの高さ
  var bottom = doch - winh; //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
  if (bottom <= $(this).scrollTop()) {
    //一番下までスクロールした時に実行

    // クローズボタンを活性
    var $close_btn = $(this).parents(".modaal-content-container").find(".close.btn");
    $close_btn.prop("disabled", false);
  }

});

});
