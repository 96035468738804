/*!
 *
 *  event-gallery.js
 *
 */

require('fancybox')($);

$.fn.eventGallery = function(){
	this.each(function(i){
		$(this).find('a').attr('rel', 'gallery-'+i);
	});

	this.find('a').fancybox({
		mouseWheel: false,
		openEffect: 'elastic',
		closeEffect: 'elastic'
	});
};

$('.event__block__gallary').eventGallery();
