/**
 * module_controll_disabled.js
 * 活性操作の設定
 */

var setDisabledForChecked = function(trigger, target_list, status) {

  $(trigger).change(function() {
    funcSetDisabledForChecked(trigger, target_list, status);
  }).change();
};
// グローバル化
window.setDisabledForChecked = setDisabledForChecked;

var setDisabledForValue = function(trigger, target_list, status, value) {

  $(trigger).change(function() {
    funcSetDisabledForValue(trigger, $(target_list), status, value);
  }).change();
};
// グローバル化
window.setDisabledForValue = setDisabledForValue;



/**********************************************************/
// summary : チェックによる表示活性・非活性制御
// params  : trigger:判定するフォーム
// params  : target_list:活性制御を行うobj
// params  : status:true…非活性化、false…活性化
// author  : Ver.1.0.0 2019/04/10 N.Yamashita 新規作成
/**********************************************************/
var funcSetDisabledForChecked = function(trigger, target_list, status) {

  if (trigger === undefined) return false;
  if (target_list === undefined) return false;
  if (status === undefined) status = true;

  for ($i = 0; $i < $(target_list).length; $i++) {

    if ($(trigger).prop('checked')) {
      $(target_list[$i]).prop("disabled", status);
      clearVal(target_list[$i], status);
    } else {
      $(target_list[$i]).prop("disabled", !status);
      clearVal(target_list[$i], !status);
    }
  }
}


/**********************************************************/
// summary : 値による表示活性・非活性制御
// params  : trigger:判定するフォーム
// params  : target_list:活性制御を行うobj
// params  : status:true…非活性化、false…活性化
// params  : value:判定値
// author  : Ver.1.0.0 2019/04/10 N.Yamashita 新規作成
/**********************************************************/
var funcSetDisabledForValue = function(trigger, target_list, status, value) {

  if ($(trigger) === undefined) return false;
  if ($(target_list) === undefined) return false;
  if (status === undefined) status = true;
  if (value === undefined) return false;

  var trigger_val;
  if ($(trigger).attr('type') == "radio" || $(trigger).attr('type') == "checkbox") {
    trigger_val = $(trigger + ":checked").val();
  } else {
    trigger_val = $(trigger).val();
  }

  for ($i = 0; $i < $(target_list).length; $i++) {

    if (trigger_val == value) {
      $(target_list[$i]).prop("disabled", status);
      clearVal(target_list[$i], status);
    } else {
      $(target_list[$i]).prop("disabled", !status);
      clearVal(target_list[$i], !status);
    }
  }
}

// 値クリア
function clearVal(target, status) {
  if(status == true && (
    $(target).type == "rado" ||
    $(target).type == "checkbox" ||
    $(target).type == "select")
  ) {
      $(target).prop("checked", false);
  } else {
    $(target).val("");
  }
}
