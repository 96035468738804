/**
 * module_controll.js
 * レイアウト操作の設定
 */

/* 共通関数プラグイン */
var common = require('lib/common_lib.js');

$(function() {

  // スライダー表示制御
  $(".slider-btn").click(function() {

    var target = "slider-target";

    // 設定値が存在する場合
    if ($(this).attr('data-target') != undefined) {
      target = $(this).data("target");
    }

    // 開いてい場合は、閉じる
    if ($(this).hasClass("active")) {
      $("[data-target=" + target + "]").removeClass("active");
      $("[data-target=" + target + "]").find(".icon").removeClass("active");


      // 開いていない場合は、開ける
    } else {
      $("[data-target=" + target + "]").addClass("active");
      $("[data-target=" + target + "]").find(".icon").addClass("active");
    }

    $("." + target).slideToggle();

  });

  // スライダー閉じるボタン
  $(".slider-block.close").click(function() {
    var $target_li = $(this).parents("li");

    var position = $target_li.offset().top - 50;

    $("html, body").animate({
      scrollTop: position
    }, "1000", "swing");

    $target_li.find(".detail-area").slideUp("1000");
    $target_li.find(".slider-block.close").hide();
    $target_li.find(".slider-block.open").show();
  });

});

// ラジオボタン等による表示制御
$(function() {
  $("input[type=radio], input[type=checkbox], select").change(function() {

    if ($(this).hasClass("disp-cont")) {

      var target_class = $(this).data('target');
      var val = "1";

      // 設定値が存在する場合
      if ($(this).attr('data-target-val') != undefined) {
        val = $(this).data('target-val');
      }

      // 設定された値の場合
      if ($(this).val() == val) {
        //add-table-targetの場合
        if (target_class == "add-table-target") {
          $(this).parents(".add-table-block").find("." + target_class).show();
        } else {
          $("." + target_class).show();
        }
      } else {
        //add-table-targetの場合
        if (target_class == "add-table-target") {
          $(this).parents(".add-table-block").find("." + target_class).hide();
        } else {
          $("." + target_class).hide();
        }

      }
    }

  });
});

// サイドメニュー位置調整
// if ($(".g-nav-side").length > 0) {
//   $(function() {
//     $(window).resize(function() {
//       var wrapper = $(".wrapper").offset();
//       var wrapper_h = $(".wrapper").height();
//       $(".g-nav-side").css("left", wrapper.left);
//       $(".g-nav-side").height(wrapper_h);
//
//       if (!common.isSP()) {
//         $(".g-nav-side").css("opacity", "1"); // edge対応
//         $(".g-nav-side").show();
//       } else {
//         $(".g-nav-side").hide();
//         $(".g-nav-side").css("opacity", "1"); // edge対応
//       }
//     }).resize();
//   });
// }

// テーブル一覧
$(".search-result-table-block-row2 th").click(function() {

  // 行数インデックス
  var tr_index = 1;

  if ($(this).hasClass("active")) {

    if ($(this).hasClass("desc")) {
      $(this).removeClass("desc");
      $(this).addClass("asc");
    } else {
      $(this).removeClass("asc");
      $(this).addClass("desc");
    }
    return false;
  }

  // 初期化
  $(".search-result-table-block-row2 th").removeClass("active");
  $(".search-result-table-block-row2 th").removeClass("desc");
  $(".search-result-table-block-row2 th").removeClass("asc");

  // 選択した項目にクラス付与
  $(this).addClass("active");
  $(this).addClass("desc");

  var first_length = $(".search-result-table-block-row2 tr:first-child th").length;

  var offset = $(this).offset();

  var ind = 1;

  // 選択項目と同一tdのleft値を取得
  $(".search-result-table-block-row2 tbody:first-of-type tr td").each(function() {
    var offset2 = $(this).offset();
    if (offset.left == offset2.left) {
      return false
    }
    ind++;
  });

  // activeクラスをリセット
  $(".search-result-table-block-row2 tbody td").removeClass("active");

  // activeクラスを付与
  $(".search-result-table-block-row2 tbody tr:nth-child(" + tr_index + ") td:nth-child(" + ind + ")").addClass("active");

});

// 固定ヘッダーの横スクロール
$(window).on("scroll", function() {
  $(".g-nav").css("left", -$(window).scrollLeft());
});

// パスワード表示
if ($(".pw-disp").length > 0) {
  $('.pw-disp').change(function() {
    if ($(this).prop('checked')) {
      $('.pw-disp-target').attr('type', 'text');
    } else {
      $('.pw-disp-target').attr('type', 'password');
    }
  });
}

// 画面位置固定エリア処理
if ($(".floating-area").length > 0 && $(".wrapper").length > 0) {
  $(function() {
    $header_height = $("header").height();
    var offset = $(".floating-area").offset();
    $(".floating-area").height($(".floating-area").height());

    $(window).scroll(function() {

      var target_scrollTop = $(window).scrollTop() + $header_height;

      // コンテンツ幅より画面が小さい場合
      if (common.isContent()) {
        $(".floating-block").css("left", -$(window).scrollLeft());
      } else {
        $(".floating-block").css("left", "auto");
      }

      if (target_scrollTop > offset.top) {
        if (!common.isSP()) {
          $(".floating-block").addClass('fixed');
        }
        if (common.isContent()) {
          $(".floating-block").addClass('is-content');
        } else {
          $(".floating-block").removeClass('is-content');
        }
      } else {
        $(".floating-block").removeClass('fixed');
        $(".floating-block").removeClass('is-content');
      }
    });

    $(window).resize(function() {

      // floating-blockの高さが変化していた場合、floating-areaの高さを修正
      var $fa_h = $(".floating-block").outerHeight();
      if ($fa_h != $(".floating-area").outerHeight()) {
        $(".floating-area").height($fa_h);
      }

      if (common.isContent()) {
        $(".floating-block").css("left", -$(window).scrollLeft());

        if ($(".floating-block").hasClass("fixed")) {
          $(".floating-block").addClass('is-content');
        } else {
          $(".floating-block").removeClass('is-content');
        }
      } else {
        $(".floating-block").css("left", "auto");
        $(".floating-block").removeClass('is-content');
      }

      if (common.isSP()) {
        $(".floating-block").removeClass("fixed");
      }
    }).resize();
  });
}

// 画面位置固定エリア処理（サイドメニューが存在しない場合）
if ($(".floating-area").length > 0 && $(".wrapper-side-none").length > 0) {
  $(function() {
    $header_height = $("header").height();
    var offset = $(".floating-area").offset();
    $(".floating-area").height($(".floating-area").height());
    $(window).scroll(function() {

      var target_scrollTop = $(window).scrollTop() + $header_height;

      if (common.isContentNoSide()) {
        $(".floating-block").css("left", -$(window).scrollLeft());

      } else {
        $(".floating-block").css("left", "auto");
      }

      if (target_scrollTop > offset.top && !common.isSP()) {
        $(".floating-block").addClass('fixed');
      } else {
        $(".floating-block").removeClass('fixed');
      }
    });

    $(window).resize(function() {

      // floating-blockの高さが変化していた場合、floating-areaの高さを修正
      var $fa_h = $(".floating-block").outerHeight();
      if ($fa_h != $(".floating-area").outerHeight()) {
        $(".floating-area").height($fa_h);
      }

      if (common.isContentNoSide()) {
        $(".floating-block").css("left", -$(window).scrollLeft());
        var opt = $(".option-area").offset();
        $(".floating-block").css("margin-left", opt.left);

      } else {
        $(".floating-block").css("left", "auto");
        $(".floating-block").css("margin-left", "auto");
      }

    }).resize();
  });
}

// 内部アンカーリンク
if ($(".anchor-link-list").length > 0) {
  $(function() {

    var link_list = [];
    $(".anchor-link-list>li").each(function() {
      var link_tmp = [];
      target = $(this).find(".btn");
      id = $(this).find(".btn").attr("href");
      $id_selector = $(id);

      offset = $id_selector.offset();
      top_s = offset.top - $(".floating-block").outerHeight();

      height_s = $id_selector.height();

      link_tmp["target"] = target; // ターゲットobj
      link_tmp["id"] = id; // リンク先ID
      link_tmp["top"] = top_s; // top値
      link_tmp["height"] = height_s; // アンカーリンク先のエリアの高さ

      // アンカーリンクリスト作成
      link_list.push(link_tmp);
    });

    $(window).scroll(function() {

      // SPの場合、activeにしない
      if (common.isSP()) {
        $(".anchor-link-list>li a").removeClass("active");
        return false;
      }

      //スクロールが最後の場合、最後の要素をactiveとする
      // if (common.isScrollEnd()) {
      //   $(".anchor-link-list>li a").removeClass("active");
      //   $(link_list[link_list.length - 1]["target"]).addClass("active");
      //   return false;
      // }

      var target_scrollTop = $(window).scrollTop() + $header_height;
      for (var key in link_list) {
        var start = link_list[key]["top"];
        var end = link_list[key]["top"] + link_list[key]["height"];

        // アンカーリンク先のエリア内の場合、activeクラスを付与
        if (target_scrollTop > start && target_scrollTop < end) {
          $(link_list[key]["target"]).addClass("active");
        } else {
          $(link_list[key]["target"]).removeClass("active");
        }
      }
    });
  });
}

// マウスホバー処理
$(function() {
  // ホバー状態の場合
  $(document).on("mouseenter", ".hover-disp", function() {
    $(this).addClass("hover");

    // テーブルのホバー吹き出し対応
    if ($(this).find(".table-float").length > 0) {
      var table_left = $(this).parents("table").offset().left;
      var left = $(this).offset().left;
      var table_top = $(this).parents("table").offset().top;
      var top = $(this).offset().top;

      var h = $(this).find(".table-float").outerHeight();

      $(this).find(".table-float").css("left", left - table_left);
      $(this).find(".table-float").css("top", top - table_top - h);
      // $(this).find(".table-float").fadeIn(100);
    }

  });

  $(document).on("mouseleave", ".hover-disp", function() {
    $(this).removeClass("hover");
    // $(this).find(".table-float").fadeOut(100);
  });

})


// checkbox, radio対応
$(function() {

  $(".input-radio").each(function() {
    if($(this).prop("checked")) {
      $(this).closest("div").addClass("checked");
    } else {
      $(this).closest("div").removeClass("checked");
    }
  });
  $(".input-check").each(function() {
    if($(this).prop("checked")) {
      $(this).closest("div").addClass("checked");
    } else {
      $(this).closest("div").removeClass("checked");
    }
  });

  $(document).on("change", ".input-radio, .input-check", function() {
    if($(this).hasClass("input-radio")) {
      var nm = $(this).attr("name");
      $("[name="+ nm +"]").closest("div").removeClass("checked");
    }
    if($(this).prop("checked")) {
      $(this).closest("div").addClass("checked");
    } else {
      $(this).closest("div").removeClass("checked");
    }
  });
});


// checkbox, radio対応（シンクサーチ用特例）
$(function() {

  $(".js-search__parts input[type=radio]").each(function() {
    if($(this).prop("checked")) {
      $(this).closest("div").addClass("checked");
    } else {
      $(this).closest("div").removeClass("checked");
    }
  });
  $(".js-search__parts input[type=checkbox]").each(function() {
    if($(this).prop("checked")) {
      $(this).closest("div").addClass("checked");
    } else {
      $(this).closest("div").removeClass("checked");
    }
  });

  $(document).on("change", ".js-search__parts input[type=radio], .js-search__parts input[type=checkbox]", function() {
    if($(this).attr("type") == "radio") {
      var nm = $(this).attr("name");
      $("[name="+ nm +"]").closest("div").removeClass("checked");
    }
    if($(this).prop("checked")) {
      $(this).closest("div").addClass("checked");
    } else {
      $(this).closest("div").removeClass("checked");
    }
  });
});

// 対象項目を隠す
$(function() {

  $(document).on("click", ".js__disp__cont", function() {
    var target_data;

    $(".js__disp__cont").removeClass("active");
    $(this).addClass("active");

    target_data = $(this).data("display");

    if(target_data == "all") {
      $(".js__disp__cont__target").fadeOut(200).promise().done(function() {
        $(".js__disp__cont__target").fadeIn(200);
      });
    } else {
      $(".js__disp__cont__target").fadeOut(200).promise().done(function() {
        $("."+target_data).fadeIn(200);
      });
    }

  });
});

$(function() {
  $(".js-image-change").hover(function() {
    var target = $(this).find("img");

    $(".js-image-change").removeClass("active");
    $(this).addClass("active");
    
    $(".js-image-change-target").attr("src", target.attr("src"));
  });
});
