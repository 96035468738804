/**
 * module_same_height.js
 * ブロックの高さをそろえる
 */

 /* 共通関数プラグイン */
 var common = require('lib/common_lib.js');

// 高さをそろえる
$(function() {
  var setPrt = $('.same-height');
  setChd1 = setPrt.find('.same-height-child');
  setChd2 = setPrt.find('.same-height-child_2');
  setChd3 = setPrt.find('.same-height-child_3');
  setChd4 = setPrt.find('.same-height-child_4');
  setChd5 = setPrt.find('.same-height-child_5');
  setChd6 = setPrt.find('.same-height-child_6');

  setChdAll1 = setPrt.find('.same-height-child-all');
  setChdAll2 = setPrt.find('.same-height-child-all_2');
  setChdAll3 = setPrt.find('.same-height-child-all_3');
  setChdAll4 = setPrt.find('.same-height-child-all_4');
  setChdAll5 = setPrt.find('.same-height-child-all_5');
  setChdAll6 = setPrt.find('.same-height-child-all_6');

  setChdAllPC = setPrt.find('.same-height-child-all-PC');
  setChdAllPC2 = setPrt.find('.same-height-child-all-PC_2');



  function argHeight(setChd, allFlag) {
    prtWidth = setPrt.outerWidth();
    chdWidth = setChd.outerWidth();
    if (allFlag == false) {
      setNum = Math.floor(prtWidth / chdWidth);
    } else {
      setNum = 3;
    }
    chdLength = setChd.length;

    setChd.css({
      height: 'auto'
    });

    setPrt.each(function() {
      h = 0;
      setChd.each(function(i) {
        var self = $(this),
          i = i + 1,
          hSet = self.outerHeight(),
          pdTop = parseInt(self.css('padding-top')),
          pdBtm = parseInt(self.css('padding-bottom')),
          boxSizing = self.css('box-sizing');
        self.addClass('heightReplace');

        if (hSet > h) {
          h = hSet;
        };

        if (boxSizing === 'border-box') {
          setPrt.find('.heightReplace').css({
            height: h
          });
        } else {
          setPrt.find('.heightReplace').css({
            height: (h - (pdTop + pdBtm))
          });
        }

        if (i % setNum == 0 || i == chdLength) {
          h = 0;
          setChd.removeClass('heightReplace');
        }
      });
    });
  }
  $(window).on('load resize', function() {
    argHeight(setChd1, false);
    argHeight(setChd2, false);
    argHeight(setChd3, false);
    argHeight(setChd4, false);
    argHeight(setChd5, false);
    argHeight(setChd6, false);

    argHeight(setChdAll1, true);
    argHeight(setChdAll2, true);
    argHeight(setChdAll3, true);
    argHeight(setChdAll4, true);
    argHeight(setChdAll5, true);
    argHeight(setChdAll6, true);

    if(!common.isSP()) {
      argHeight(setChdAllPC, true);
      argHeight(setChdAllPC2, true);
    }

  }).resize();
});
