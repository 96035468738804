/**
 * module_g-nav.js
 * g-navの設定
 */

 /* megamenu.jsプラグイン */
 require('lib/megamenu.js');

 var common = require('lib/common_lib.js');

 // SPメニュー
 $(function() {
   $(".spmenu-block, .sp-bk").on("click", function() {
     $(".gnav__sp").toggleClass("sp-active");
     $(".spmenu-block").toggleClass("sp-active");
     $(".sp-bk").toggleClass("sp-active");
     $("#side").toggleClass("display-none");
   });
 });

 $(function() {
   // $(".gnav__sp li a").on("click", function() {
   //   $(".gnav__sp").removeClass("sp-active");
   //   $(".spmenu-block").removeClass("sp-active");
   //   $(".sp-bk").removeClass("sp-active");
   //   $("#page-top").removeClass("display-none");
   // });
 });

 $(function() {
 $(".gnav__sp .plus").on('click', function() {
   var open_arrow = $(this).parents("li").find(".child");

   if (open_arrow.hasClass("open")) {
     open_arrow.slideUp("slow");
     open_arrow.removeClass("open");
     $(this).removeClass("open");
   } else {
     open_arrow.slideDown("slow");
     open_arrow.addClass("open");
     $(this).addClass("open");
     // 	$(this).css({transform: 'rotate(' + (-90) + 'deg)'});
   }
 });

 // アイコンを囲うタグの指定
 var toggles = $('.gnav__sp .plus');
 //アイコンをクリックでクラスの切替え
 toggles.click( function (event) {
   event.preventDefault();
   $(this).find("i").toggleClass("on");
 });
});

$(function() {
$(".footer__top__right__sp .plus").on('click', function() {
  var open_arrow = $(this).parents("li").find(".child");

  if (open_arrow.hasClass("open")) {
    open_arrow.slideUp("slow");
    open_arrow.removeClass("open");
    $(this).removeClass("open");
  } else {
    open_arrow.slideDown("slow");
    open_arrow.addClass("open");
    $(this).addClass("open");
    // 	$(this).css({transform: 'rotate(' + (-90) + 'deg)'});
  }
});

// アイコンを囲うタグの指定
var toggles = $('.footer__top__right__sp .plus');
//アイコンをクリックでクラスの切替え
toggles.click( function (event) {
  event.preventDefault();
  $(this).find("i").toggleClass("on");
});
});


// SP時のローカルメニューアコーディオン
$(function() {
  var open_arrow = $(".c__service__local__list");
  $(".c__service__local__list__title").on('click', function() {


    if (open_arrow.hasClass("open")) {
      open_arrow.slideUp("slow", function() {
        open_arrow.removeAttr('style');
      });
      open_arrow.removeClass("open");
    } else {
      open_arrow.slideDown("slow");
      open_arrow.addClass("open");
      // 	$(this).css({transform: 'rotate(' + (-90) + 'deg)'});
    }
  });

  $(window).resize(function() {
    if (!common.isSP()) {
      open_arrow.removeAttr('style');
      open_arrow.removeClass("open");
      $('.c__service__local__list__title').find(".plus i").removeClass("on");
    }
  }).resize();

  // アイコンを囲うaタグの指定
  var toggles = $('.c__service__local__list__title');
  //アイコンをクリックでクラスの切替え
  toggles.click( function (event) {
    event.preventDefault();
    $(this).find(".plus i").toggleClass("on");
  });
});


$(function() {
  $(".js-search-box").on('click', function() {
    $(".search__block").toggleClass("on");
    $(".search__block__bk").toggleClass("active");

  });

});
